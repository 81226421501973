.filterBtn {
    padding: 7px 13px !important;
    background-color: #1565c0 !important;
}

.resetBtn {
    padding: 7px 1px !important;
    background-color: #c51031 !important;
}

.tableFilterOption {
    background-color: rgb(229, 246, 253);
    padding: 9px;
}
