.wrapper {
    --max-width: 300px;
}

.fieldWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: var(--max-width);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding-left: 8px;
    box-sizing: border-box;
}

.field {
    flex: 1;
}

.fieldClear {
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.36);
    margin-right: 10px !important;
}

.fieldClearDisabled {
    opacity: 0;
}

.fieldEnter {
    padding: 0 !important;
    height: 34px;
    width: 36px;
    border-radius: 0 3px 3px 0  !important;
    background-color: #1976d2 !important;
}

.fieldEnter:hover {
    background-color: rgba(25, 118, 210, 0.83);
}

.fieldEnterIcon {
    font-size: 30px;
    color: white;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 4px;
    max-width: var(--max-width);
    width: 100%;
    max-height: 100px;
    overflow-x: auto;
}

