.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.controlsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    height: 36px;
}

.sections {
    display: grid;
    gap: 20px;
    grid-template-columns: 4fr 3fr;
    grid-template-rows: min-content 1fr;
    max-width: 1920px;
    width: 100%;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 15px;
    min-width: 240px;
    width: 100%;

    padding: 20px;
    box-sizing: border-box;

    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.field {
    width: 100%;
}

.dateField {
    min-width: 100px;
}

.fieldsRow {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    & .field {
        width: auto;
        flex: 1;
    }
}

.shortFields {
    grid-row: 1 / 1;
    grid-column: 1 / span 2;
    justify-self: center;
    max-width: 800px;
}

.fieldResetButtonBlock {
    display: flex;
    justify-content: flex-end;
    min-height: 36px;
}

.specBlock {
    grid-area: 2 / 2;
}

.bodyTplBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: 2 / 1;
}

.bodyTplOptions {
    position: relative;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #9e9e9e;
}

.bodyTplOptionsLabel {
    position: absolute;

    top: -20px;
    left: 0;

    font-size: 12px;
    opacity: 0.7;
}

@media screen and (max-width: 700px) {
    .sections {
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content min-content;
    }

    .specBlock {
        grid-area: 3 / span 1;
    }

    .bodyTplBlock {
        grid-area: 2 / span 1;
    }
}

@media screen and (max-width: 560px) {
    .fieldsRowWrap {
        &.fieldsRow {
            flex-direction: column-reverse;
        }
    }
}
