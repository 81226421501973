.orgIssuesCard {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: calc(50% - 31px);
}

.orgName {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}

.orgIssuesList {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    flex-wrap: wrap;
    flex: 2;
}

@media (max-width: 1300px) {
    .orgIssuesCard {
        width: 100%;
    }
}
