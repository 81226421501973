.uploadAreaWrapper {
    padding: 0 50px 0 10px;
}

.uploadArea {
    position: relative;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    min-width: 200px;
    min-height: 200px;
    padding: 20px;
}

.uploadArea:hover {
    transform: scale(1);
    box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
}

.infoMark {
    position: absolute !important;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
}

.uploadAreaAndTable {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.tableAndButton {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    max-width: 1200px;
}

.table {
    width: 100%;
}

.saveAndClearButtons {
    display: flex;
    gap: 10px;
}
