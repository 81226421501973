.labelsList {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px;
}

.inputAndButton {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;

    align-items: center;
}

.input {
   flex: 2;
}
