.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.frame {
    display: grid;
    grid-template-columns: 1fr 0;
    grid-template-rows: 1fr 0;
}

.image {
    width: 100%;
    height: 100%;
}

.verticalDragPlace,
.horizontalDragPlace {
    background-color: #e5e5e5;
    border-bottom-right-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.verticalDragPlace {
    border-top-right-radius: 5px;
    grid-column: 2;
    grid-row: 1 / 2;
    flex-direction: column;
    width: 12px;
    height: 100%;
}

.verticalDragIcon {
    transform: rotate(90deg);
}

.horizontalDragPlace {
    border-bottom-left-radius: 5px;
    grid-column: 1 / 2;
    grid-row: 2;
    height: 12px;
    width: 100%;
}

.grab {
    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}

.fields {
    display: flex;
    gap: 20px;
}

.field {
    width: 100%;
}
