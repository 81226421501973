.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
    width: 100%;
}

.titleAndFilters {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.title {
    margin-right: 50px !important;
}

.filters {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-end;
}

.actionButtons > button {
    height: 36px;
    margin: 0;
    white-space: nowrap;
}

@media (max-width: 600px) {
    .header {
        flex-wrap: wrap;
    }
}
