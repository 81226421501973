.cardsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1920px;
}

.card {
    width: 100%;
    min-width: 280px;
    max-width: 360px;
    min-height: 100%;
}

.cardHeader {
    text-align: center;
    padding-top: 8px;
    border-bottom: solid 1px #efefef;
    margin: 0 !important;
    background: #e4f2ff;
}
