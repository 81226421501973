.formAndTable {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.userForm {
    display: flex;
    gap: 10px;
    align-items: center;
}

.tableAndButton {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    max-width: 1200px;
}

.table {
    width: 100%;
}

.saveAndClearButtons {
    display: flex;
    gap: 10px;
}
