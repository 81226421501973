.searchInput {
    flex: 1;
    border: 0;
    font-size: 18px;
    padding: 15px;
}

.searchInput:focus {
    outline: none;
}

.inputAndClearButtonArea {
    display: flex;
    flex: 1;
    background-color: white;
    border: solid 1px #ccc;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 1px;

    -webkit-box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07);
}

.searchContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.searchButton {
    width: 17% !important;
    max-width: 120px !important;
    min-width: 60px !important;
    border: 1px solid #ccc !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    -webkit-box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07) !important;
    -moz-box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07) !important;
    box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07) !important;
}

.searchButton:hover {
    -webkit-box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 7px 0 15px 2px rgba(0, 0, 0, 0.07);
}

.inputAndButton {
    display: flex;
}

.clearButton {
    font-size: 30px !important;
    cursor: pointer;
    opacity: 0.4;
}

.clearButtonContainer {
    display: grid;
    place-items: center;
    height: 60px;
    width: 50px;
}

.optionsContainer {
    position: absolute;
    left: 0;
    top: 65px;
    height: 250px;
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0 0 5px 5px;
    z-index: 10000;
}

.optionsContainer::-webkit-scrollbar {
    display: none;
}

.option {
    display: flex;
    height: 50px;
    padding: 0 14px;
    align-items: center;
    color: black;
    cursor: pointer;
    text-decoration: none;
}

.option:hover {
    background-color: lightgrey;
}

.option.active {
    background-color: #ccc;
}

.optionText {
    margin-left: 10px;
}

.inputAndButtonRoot {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 750px;
    min-width: 200px;
}
