.wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.filters {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.filterControls {
    display: flex;
    gap: 10px;
    height: 36px;
}

.datePickersContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding-right: 10px;
}

.rangePicker {
    display: flex;
    gap: 10px;
}

.field {
    min-width: 150px !important;
}

.datePicker {
    max-width: 170px;
}

.applyButton {
    position: relative;
}

.infoMark {
    position: absolute !important;
    top: -8px;
    right: -8px;
    font-size: 22px !important;
}

@media (max-width: 480px) {
    .rangePicker {
        flex-wrap: wrap;
    }
}
