.content {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
}

.backgroundColor {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #efefef;
    z-index: -1;
}
