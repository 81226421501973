.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.controlsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    height: 36px;
}

.sections {
    display: grid;
    gap: 20px;
    grid-template-columns: 3fr 4fr;
    grid-template-rows: min-content 1fr;
    max-width: 1920px;
    width: 100%;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 15px;
    min-width: 240px;
    width: 100%;

    padding: 20px;
    box-sizing: border-box;

    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.fieldWithControl {
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.field {
    width: 100%;
}

.checkboxesGroup {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

@media screen and (max-width: 840px) {
    .sections {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}
