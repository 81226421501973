.header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
    max-width: 1420px;
    width: 100%;
    box-sizing: border-box;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navRoutes {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.route {
    padding: 0;
    display: flex;
    height: 40px;

    color: white !important;
}

.mobileMenuButton {
    display: block;
}

.settings {
    display: flex;
}

@media (min-width: 600px) {
    .mobileMenuButton {
        display: none;
    }
}

@media (max-width: 600px) {
    .navRoutes {
        display: none;
    }

    .logo {
        width: 100%;
    }
}
