.errorSections {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
}

.otherErrorsSection {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.otherError {
    width: 100%;
    max-width: 400px;
}
