.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.orgMissingFieldsContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}

.emptyBlock {
    display: flex;
    width: 100%;
    font-weight: bold;
    box-sizing: border-box;
}
