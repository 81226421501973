.buttonsAndForm {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.switchField {
    display: flex;
    gap: 5px;
    align-items: center;
}

.marginLeft5px {
    margin-left: 5px;
}

.fixSwitchFieldRightAlign {
    margin-right: -20px;
}

.fixCenterOfSwitchField {
    margin-bottom: -2px;
}
