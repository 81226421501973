.resizerAndFields {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.fields {
    display: flex;
    gap: 10px;
    min-width: 300px;
}

.field {
    width: 100%;
}
