.section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 12px 12px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: calc(100% - 30px);
}

.organisations {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    padding-left: 20px;
    font-weight: normal;
}

.organisation {
    display: flex;
    gap: 5px;
    align-items: center;
}
