.uploadAndPreviewArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.imageErrorAndPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageErrorText {
    color: #d32f2f;
    margin: 0
}
